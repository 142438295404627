<template>
<div id="setpassword">
  <LoginFormComponent
    formType="SET_PASSWORD"
    :formTitle="$t('SET_PASSWORD_PAGE_FORM_TITLE_RESET_PASSWORD')"
    :btnLabel="$t('SET_PASSWORD_PAGE_BUTTON_SUBMIT')"
    :label1="$t('SET_PASSWORD_PAGE_LABEL_1')"
    :label2="$t('SET_PASSWORD_PAGE_LABEL_2')"
    :text1="$t('SET_PASSWORD_PAGE_TEXT_1')"
    :text2="$t('SET_PASSWORD_PAGE_TEXT_2')"
    :text3="$t('SET_PASSWORD_PAGE_TEXT_3')"
    :text4="$t('SET_PASSWORD_PAGE_TEXT_4')"
    :text5="$t('SET_PASSWORD_PAGE_TEXT_5')"
    :text6="$t('SET_PASSWORD_PAGE_TEXT_6')"
    @update="update"
    :rulesRequired="true"
    :rulesMinPassword="true"
    :rulesConfirmed="true"
  />
  </div>
</template>

<script>
import LoginFormComponent from "../../components/LoginFormComponent";
import endpoints from "../../constants/endpoints";
import responseHelper from '@/utils/response-helper';
export default {
  name: "SetPassword",
  props: {
    token: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isSetPass: false
    };
  },
  components: { LoginFormComponent },
  async mounted () {
    let token=this.$route.query.token;
    this.token=token;
    const resultTokens = await this.$http.get(endpoints.checkToken(this.token));
    console.log(resultTokens);
    if(resultTokens.data.token_sts === 'EXPIRED' && resultTokens.data.user_sts === 'ACTIVATED'){
      this.$store.state.platform.checkExpired = true;
      this.$router.push("/forgot-password");
    }
    // else if(resultTokens.data.token_sts === 'EXPIRED' && resultTokens.data.user_sts === 'UNACTIVATED'){
    //   this.$store.state.platform.checkExpired = true;
    //   this.$router.push("/login");
    // }
  },
  methods: {
    async update({ password }) {
      let re1 = /^[a-zA-Z0-9!@#$%^&*()-_=+]{10,30}$/;
      let re2 = re1.test(password);
      if(re2){
      try {
        let token =
          this.token.slice(-1) == "." ? this.token.slice(0, -1) : this.token;
        const params = {
          verify_token: token,
          password: password
        };
       console.log('bababa',params);
        const result = await this.$http.post(endpoints.setPassword, params);
        if (result) {
          this.$notify({
            group: "notifications",
            type: "success",
            title: this.$t("SET_PASSWORD_PAGE_TITLE_NOTIFY_SUCCESS"),
            text: this.$t("SET_PASSWORD_PAGE_TEXT_NOTIFY_SUCCESS")
          });
          this.toLogin();
        }
      } catch (err) {
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("SET_PASSWORD_PAGE_TITLE_NOTIFY_ERROR"),
          text: responseHelper.getErrorMessage(err)
        });
        this.$router.push("/forgot-password");
      }
      }else{
        this.$notify({
          group: "notifications",
          type: "error",
          title: this.$t("SET_PASSWORD_PAGE_TITLE_NOTIFY_ERROR"),
          text: this.$t("SET_PASSWORD_PAGE_TEXT_NOTIFY_RULE_ERROR")
        })
      }
    },
    toLogin() {
      this.$router.push("/login");
    }
  }
};
</script>

<style lang='scss' scoped>
  #setpassword /deep/{ 
    .card-body{
      h1{
        margin-bottom: 0 !important
      }
      .input1 label, .input2 label{
        margin-left: 0;
        padding-right: 10px !important;
      }
    }
    .error-msg{
      padding-left: 145px;
    }
  }
</style>
