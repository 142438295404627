<template>
  <div id="login" class="d-flex justify-content-center align-items-center min-vh-100">
      <div class="logo-moonstake">
          <img src="@/assets/images/logo_horizontal_black.svg" />
      </div>
      <div class="expired-tooltip" v-if="isExpired==true && isShowErrorExpired==true">
        <p>{{$t("LOGIN_FORM_COMPONENT_CONTENT_RESET_PASSWORD_LINK_EXPERIED")}}
          <i class="fas fa-times" @click="isShowErrorExpired=false"></i>
        </p>
      </div>
    <CRow class="box-container">
      <CCol md="12">
        <CCard class="p-4">
          <CCardBody class="custom-card-body">
            <ValidationObserver ref="observer" @submit.prevent="actionHandler">
              <CForm>
                <div v-if="formType === 'FORGOT_PASSWORD'" class="return-login">
                    <a @click="toLogin()"><CIcon :content="cilArrowLeft" />{{$t("LOGIN_FORM_COMPONENT_BUTTON_BACK_TO_LOG_IN")}}</a>
                </div>
                <h1 class="mb-3">{{formTitle}}</h1>
                <div v-if="formType === 'FORGOT_PASSWORD'" class="forgot-des">{{textDont}}</div>
                <p v-if="formType === 'LOGIN'" class="text-muted">{{$t("LOGIN_FORM_COMPONENT_CONTENT_WELCOME_BACK")}}</p>

                <div v-if="label1">
                  <ValidationProvider
                    :name="label1"
                    :rules="getRules(true)"
                    v-slot="{ errors }"
                    vid="password"
                  >
                    <div>
                        <CInput
                          :class="formType==='SET_PASSWORD' || formType==='ACTIVE_USER' ? 'input1 input--w' : 'input1'"
                          v-model="input1"
                          :type="(formType === 'RESET_PASSWORD' && isShowPass === false ) || (formType==='ACTIVE_USER' && isShowPass === false) || (formType==='SET_PASSWORD' && isShowPass === false ) ? 'password' : 'text'"
                          :label="label1"
                          autocomplete="username email"
                          :placeholder="text6"
                        >
                        <template #prepend v-if="formType==='SET_PASSWORD'">
                          <i v-if="isShowPass===false" class="far fa-eye-slash" @click="isShowPass= !isShowPass"></i>
                          <i v-else class="far fa-eye" @click="isShowPass= !isShowPass"></i>
                        </template>
                      </CInput>
                      <CRow class="row-err">
                        <CCol class="error-msg-wrap">
                          <p class="error-msg">{{errors[0]}}</p>
                        </CCol>
                      </CRow>
                    </div>
                  </ValidationProvider>
                </div>

                <div v-if="label2">
                  <ValidationProvider :name="label2" :rules="getRules()" v-slot="{ errors }">
                    <div>
                    <CInput
                      :class="formType==='SET_PASSWORD' ? 'input2 input--w dn-ico' : 'input2'"
                      v-model="input2"
                      :label="label2"
                      :type="isShowPass===true ? 'text' : 'password'"
                      autocomplete="curent-password"
                      :placeholder="formType === 'LOGIN' ? $t('LOGIN_FORM_COMPONENT_PLACEHOLDER_ENTER_YOUR_PASSWORD') : text6 "
                    >
                      <template #prepend>
                        <i v-if="isShowPass===false" class="far fa-eye-slash" @click="isShowPass= !isShowPass"></i>
                        <i v-else class="far fa-eye" @click="isShowPass= !isShowPass"></i>
                      </template>
                    </CInput>
                     <CRow class="row-err">
                        <CCol class="error-msg-wrap">
                          <p class="error-msg">{{errors[0]}}</p>
                        </CCol>
                      </CRow>
                    </div>
                  </ValidationProvider>
                </div>

                <!-- <div v-if="formType === 'LOGIN' || formType === 'FORGOT_PASSWORD' ">
                  <CRow
                    :class="formType === 'FORGOT_PASSWORD' ? 'mt-8' : ''"
                    class="justify-content-end mb-3"
                  >
                    <CCol class="captcha">
                      <vue-recaptcha
                        ref="grecaptcha"
                        :sitekey="siteKey"
                        @expired="captchaExpired"
                        @verify="getCaptcha"
                      />
                    </CCol>
                  </CRow>
                </div> -->
                
                <div v-if="formType === 'LINK_EXPIRED_USER'">
                    <p v-html="$t('LOGIN_FORM_COMPONENT_CONTENT_LINK_EXPIRED_USER')"></p>
                    <CButton color="link" class="resend-email" @click="clickResend()">{{$t("LOGIN_FORM_COMPONENT_BUTTON_RESEND_EMAIL")}}</CButton>
                </div>

                <div v-if="formType === 'RESET_PASSWORD' || formType === 'SET_PASSWORD'" class="group-tick">
                    <div class="w-49">
                      <ValidationProvider 
                      :rules="getMinPass()" 

                      >
                        <div :class= className1 ><CIcon class="icon-config mr-1 mg-3" :class = classCheck1  :content= tick1 />
                        {{text1}}</div>
                      </ValidationProvider>
                      <ValidationProvider :rules="getLowerPass()"  >
                        <div :class= className2 ><CIcon class="icon-config mr-1" :class = classCheck2 :content= tick2 />{{text2}}</div>
                      </ValidationProvider>
                      <ValidationProvider :rules="getUpperPass()"  >
                        <div :class= className3><CIcon class="icon-config mr-1 mg-3" :class = classCheck3 :content= tick3 />{{text3}}</div>
                      </ValidationProvider>
                    </div>
                    <div class="w-49 w-49-r">
                      <ValidationProvider :rules="getNumberPass()"  >
                        <div :class= className4><CIcon class="icon-config mr-1 mg-3" :class = classCheck4 :content= tick4  />{{text4}}</div>
                      </ValidationProvider>
                      <ValidationProvider :rules="getSpecialPass()"  >
                        <div :class= className5><CIcon class="icon-config mr-1 " :class = classCheck5 :content= tick5  />{{text5}}</div>
                      </ValidationProvider>
                    </div>
                </div>
                <CRow v-if="formType === 'FORGOT_PASSWORD' || formType === 'SET_PASSWORD'" class="justify-content-center mt-4">
                  <CButton type="submit" class="btn-fogot px-4">{{btnLabel}}</CButton>
                </CRow>
                <CRow v-if="formType !== 'FORGOT_PASSWORD' && formType !== 'SET_PASSWORD' && formType !== 'LINK_EXPIRED_USER'" class="justify-content-center mt-4">
                  <CButton type="submit" class="btn-login px-4">{{btnLabel}}</CButton>
                </CRow>
                <CCol
                        :class="formType ==='FORGOT_PASSWORD' ? 'invisible' : ''"
                        class="forgot-text"
                        cols="12"
                >
                <CButton v-if="formType === 'LOGIN'" @click="clickForgotPassword" color="link">{{$t('LOGIN_FORM_COMPONENT_BUTTON_FORGOT_PASSWORD')}}</CButton>
                </CCol>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { cilX, cilArrowLeft, cilCheckAlt } from "@coreui/icons";
import endpoints from '../constants/endpoints';
import SetPassword from '../views/pages/SetPassword';
import { mapState, mapActions } from "vuex";

export default {
  props: {
    formTitle: {
      type: String,
      default: ""
    },
    textDont:{
      type: String,
      default:""
    },
    formType: {
      type: String,
      default: ""
    }
    ,
    btnLabel: {
      type: String,
      default: "Ok"
    },
    label1: {
      type: String,
      default: undefined
    },
    label2: {
      type: String,
      default: undefined
    },
    label3: {
      type: String,
      default: undefined
    },
    rulesRequired: {
      type: Boolean,
      default: false
    },
    rulesEmail: {
      type: Boolean,
      default: false
    },
    rulesMinPassword: {
      type: Boolean,
      default: false
    },
    rulesConfirmed: {
      type: Boolean,
      default: false
    },
    siteKey: {
      type: String,
      default: ""
    },
    text1:{
      type: String,
      default:""
    },
    text2:{
      type: String,
      default:""
    },
    text3:{
      type: String,
      default:""
    },
    text4:{
      type: String,
     
      default:""
    },
    text5:{
      type: String,
      default:""
    },
    text6:{
      type: String,
      default: ""
    },
    text7:{
      type: String,
      default: ""
    },
    isExpired:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      input1: "",
      input2: "",
      isShowPass: false,
      isShowErrorExpired: true,
      captchaToken: "",
      cilX,
      cilArrowLeft,
      cilCheckAlt,
      className1:{
        type: String,
        default:""
      },
      classCheck1:{
        type: String,
        default: ""
      },
      tick1:{
        type: Object,
        default: function(){
          return cilX;
        }
      },
      className2:{
        type: String,
        default:""
      },
      classCheck2:{
        type: String,
        default: ""
      },
      tick2:{
        type: Object,
        default: function(){
          return cilX;
        }
      },
      className3:{
        type: String,
        default:""
      },
      classCheck3:{
        type: String,
        default: ""
      },
      tick3:{
        type: Object,
        default: function(){
          return cilX;
        }
      },
      className4:{
        type: String,
        default:""
      },
      classCheck4:{
        type: String,
        default: ""
      },
      tick4:{
        type: Object,
        default: function(){
          return cilX;
        }
      },
      className5:{
        type: String,
        default:""
      },
      classCheck5:{
        type: String,
        default: ""
      },
      tick5:{
        type: Object,
        default: function(){
          return cilX;
        }
      }
    };
  },
  async created() {
    await this.$recaptchaLoaded();
  },
  methods: {
    ...mapActions([
       "reSendOtp",
    ]),
    captchaExpired() {
      this.captchaToken = "";
    },
    getCaptcha(token) {
      this.captchaToken = token;
    },
    async resetCaptcha() {
      this.captchaToken = await this.$recaptcha('login');
      // const recaptcha = this.$recaptchaInstance;

      // this.$refs.grecaptcha.reset();
    },
    getMinPass(){
          let re1 = this.input1;
          let re2 = /.{10,}/;
          if(re2.test(re1) === true){
              this.className1 = "passed",
              this.classCheck1 = "check"
              this.tick1 = cilCheckAlt; 
          }
          else{
              this.className1 = "",
              this.classCheck1 = ""
              this.tick1 = cilX
          }
    },
    getLowerPass(){
      let re1 = this.input1;
          let re2 = /[a-z]+/;
          if(re2.test(re1) === true){
              this.className2 = "passed",
              this.classCheck2 = "check"
              this.tick2 = cilCheckAlt
          }
          else{
              this.className2 = "",
              this.classCheck2 = ""
              this.tick2 = cilX
          } 
    },
    getUpperPass(){
      let re1 = this.input1;
          let re2 = /[A-Z]+/;
          if(re2.test(re1) === true){
              this.className3 = "passed",
              this.classCheck3 = "check"
              this.tick3 = cilCheckAlt
          }
          else{
              this.className3 = "",
              this.classCheck3 = ""
              this.tick3 = cilX
          } 
    },
    getNumberPass(){
      let re1 = this.input1;
          let re2 = /[0-9]/;
          if(re2.test(re1) === true){
              this.className4 = "passed",
              this.classCheck4 = "check"
              this.tick4 = cilCheckAlt
          }
          else{
              this.className4 = "",
              this.classCheck4 = ""
              this.tick4 = cilX
          } 
    },
    getSpecialPass(){
      let re1 = this.input1;
          let re2 = /[-!$%^@&*()_+|~=`{}\[\]:";'<>?,.\/]/;
          if(re2.test(re1) === true){
              this.className5 = "passed",
              this.classCheck5 = "check"
              this.tick5 = cilCheckAlt
          }
          else{
              this.className5 = "",
              this.classCheck5 = ""
              this.tick5 = cilX
          } 
    },
    getRules(fieldPassword = false) {
      if (fieldPassword) {
        if (this.rulesRequired && this.rulesEmail) {
          return "required|email";
        }

        if (this.rulesRequired && this.rulesMinPassword) {
          return {
            required: true,
            min: 6,
            max: 30,
            regex: /^[a-zA-Z0-9!@#$%^&*()-_=+]{6,30}$/
          };
        }

        if (this.rulesRequired) {
          return "required";
        }
      }

      // form confirm
      if (this.rulesRequired && this.rulesConfirmed) {
        return "required|confirmed:password";
      }

      //form login
      if (this.rulesRequired) {
        return {
          required: true,
          min: 6,
          max: 30,
          regex: /^[a-zA-Z0-9!@#$%^&*()-_=+]{6,30}$/
        };
      }
    },
    async actionHandler() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.captchaToken = await this.$recaptcha('login'); 
      if (this.formType === "LOGIN") {
        const data = {
          email: this.input1,
          password: this.input2,
          captchaToken: this.captchaToken
        };
        this.$emit("login", data);
      }

      if (this.formType === "FORGOT_PASSWORD") {
        const data = {
          email: this.input1,
          captchaToken: this.captchaToken
        };
        this.$emit("send", data);
      }

      if (
        this.formType === "SET_PASSWORD" ||
        this.formType === "RESET_PASSWORD"||
        this.formType === "ACTIVE_USER"
      ) {
        if (this.input1 !== this.input2) {
          this.$notify({
            group: "notifications",
            type: "error",
            title: this.$t("LOGIN_FORM_COMPONENT_TITLE_NOTIFY_ERROR"),
            text: this.$t("LOGIN_FORM_COMPONENT_TEXT_NOTIFY_ERROR")
          });
          return;
        }

        const data = { password: this.input1 };
        this.$emit("update", data);
      }
    },
    clickForgotPassword() {
      this.$router.push("/forgot-password");
    },
    async clickResend(){
        const payload = {
            verify_token: this.$route.query.token,
        }
        const response = await this.reSendOtp(payload);
    },
    toLogin() {
      this.$store.state.platform.checkExpired = false;
      this.$router.push("/login");
    }
  },
  name: "LoginFormComponent",
  
  
};
// console.log(regex.text2);
</script>

<style lang="scss">
  input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-text-fill-color: black;
  }
.resend-email{
  text-align: center;
  color: white;
  height: 35px;
  border-radius: 4px;
  background-color: #3B4B7E;
  padding: 0 20px;
  &:hover{
    color: white;
  }
}
.return-login {
  a {
    cursor: pointer;
    color: #695FE5;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
     .c-icon{
         height: 14px;
         margin-top: -3px;
         margin-right: 3px;
     }
  }
  margin-top: 16px;
  padding-bottom: 20px;
  text-align: left;
}
#login {
    flex-direction: column;
    .logo-moonstake{
        margin-bottom: 40px;
        img{
          @media ( max-width: 767px ){
            width: 250px;
          }
      }
    }
  .box-container {
    width: 600px;
    @media (max-width: 1024px){
      width: 80%;
    }
    @media (max-width: 767px){
      width: 100%;
    }
    .p-4{
      padding: 1.5rem 15px !important;
    }
    .custom-card-body {
      @media (min-width: 1100px) {
        width: 100%;
      }
      @media (max-width: 767px){
        padding: 0;
      }
      .captcha {
        margin-top: 15px;
        padding-left: 105px;
        @media (max-width: 700px) {
          order: 2;
          padding-left: 15px;
        }
        div{
          width: 100% !important;
          @media (max-width: 700px) {
            text-align: center;
          }
        }
      }
      .forgot-text {
        margin-top: 3px;
        text-align: center;
        color: #695FE5;
        font-size: 14px;
        line-height: 17px;
        @media (max-width: 700px) {
          text-align: right;
          margin-right: -10px;
          margin-bottom: -10px;
          color: #3C4B64;
          font-size: 35px;
          line-height: 42px;
        }
      }
      .forgot-des{
        padding-bottom: 18px;
        color: #3E4B65;
        font-size: 14px;
        line-height: 22px;
      }

      .input1, .input2{
          color: #3E4B65;
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          display: flex;
          justify-content: flex-end;
          label{
            width: 90px;
            text-align: right;
            padding-right: 15px !important;
            line-height: 35px;
            margin: 0;
          }
          input{
              width: calc(100% - 90px);
              box-sizing: border-box;
              height: 35px;
              border: 1px solid #D7DBE0;
              border-radius: 4px;
              background-color: none;
              &:focus{
                box-shadow: none !important;
              }
          }
          .input-group{
            width: calc(100% - 90px);
            input{
              width: 100%;
            }
          }
      }
      .input1,
      .input2{
        &.dn-ico{
          .input-group-prepend{
            display: none;
          }
        }
        &.input--w{
          label{
            width: 145px;
            margin: 0;
            padding: 0;
            line-height: 35px;
            text-align: right;
          }
          .input-group{
            width: calc(100% - 145px);
            @media(max-width: 1024px){
              width: 74%;
            }
            @media(max-width: 767px){
              width: calc(100% - 145px);
            }
          }
        }
        .input-group-prepend{
          position: absolute;
          z-index: 1;
          top: 0;
          right: 10px;
          bottom: 0;
          align-items: center;
          z-index: 99;
          i{
          font-size: 18px;
        }
      }
    }
  }
  .text-muted {
    margin-bottom: 10px;
  }

  .form-group {
    margin-bottom: 0;
  }
  input {
    @media (min-width: 1100px) {
      width: 300px;
    }
  }
  .input1{
    margin-top: 41px;
  }
  .input2 {
    margin-bottom: 0;
    margin-top: 27px;
    input{
      border: none !important;
      border-radius: 0 !important;
      width: 92% !important;
      flex: inherit !important;
      &:focus{
        box-shadow: none !important;
      }
    }
    .input-group{
      border: 1px solid #D7DBE0;
      border-radius: 4px;
      &:focus{
        box-shadow: 0 0 5px #dadada !important;
      }
    }
  }
  .btn-login {
    color: white;
    height: 35px;
    width: 94%;
    border-radius: 4px;
    background-color: #3B4B7E;
  }
  .btn-fogot{
    @extend .btn-login;
    width: 40%;
  }
  .w-49{
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    margin-bottom: 12px;
      .passed{
          color: #B4B4B4;
      }
      div{
          margin-bottom: 7px;
          color: #3E4B65;
          font-size: 14px;
          font-weight: 300;
          line-height: 22px;
      }
      .check{
          color: #7ED321;
      }
      .mg-3{
          margin-top: -3px;
      }
      .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size){
          width: 14px;
          height: 14px;
          margin-right: 8px;
      }
  }
  .mg-112{
      margin-left: 112px;
  }
    .mg-49{
      margin-left: 49px;
    }
  }
  .mt-8 {
    margin-top: 8px;
  }
}
.expired-tooltip{
  position: relative;
  text-align: center;
    padding: 0 30px 0 15px;
  border: 1px solid #F8CFCF;
  border-radius: 5px;
  background-color: #FADDDD;
  margin-bottom: 10px;
  p{
    color: #772B35;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    padding: 18.5px 0;
    margin: 0;
    i{
      position: absolute;
      font-size: 14px;
      top: 21px;
      right: 10px;
    }
  }
}
.error-msg{
  color: red !important;
  text-align: left;
  padding-left: 90px;
  margin: 6px 0 0 0 !important;
}
.group-tick{
    display: flex;
    width: calc( 100% - 145px );
    margin-left: 145px;
    .w-49-r{
      margin-left: 20px;
    }
    @media( max-width: 767px ){
      flex-direction: column;
      .w-49{
        margin-bottom: 0 !important;
      }
      .w-49-r{
        margin: 0 !important;
      }
    }
}
</style>
