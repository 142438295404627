import { render, staticRenderFns } from "./SetPassword.vue?vue&type=template&id=5de11e52&scoped=true&"
import script from "./SetPassword.vue?vue&type=script&lang=js&"
export * from "./SetPassword.vue?vue&type=script&lang=js&"
import style0 from "./SetPassword.vue?vue&type=style&index=0&id=5de11e52&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5de11e52",
  null
  
)

export default component.exports